import Resource from './resource';
import ScopedResource from './scoped-resource';

import Account from './resources/account';
import Order from './resources/order';
import ResetPassword from './resources/reset-password';
import User from './resources/user';
import UserStoreCredit from './resources/user-store-credit';

const API = {
  Account: new Account(),
  Auth: new Resource({ rootKey: 'auth', uri: '/auth' }),
  Contact: new Resource({ rootKey: 'contact', uri: '/contacts' }),
  Order: new Order({ rootKey: 'order', uri: '/orders' }),
  OrderStoreCredit: new ScopedResource({
    rootKey: 'store-credits',
    scope: '/orders',
    uri: '/store-credits',
  }),
  OrderTimeline: new Order({
    rootKey: 'order_timeline',
    uri: '/order_timeline_events',
  }),
  Payment: new ScopedResource({
    rootKey: 'data',
    scope: '/orders',
    uri: '/checkout-url',
  }),
  Pix: new ScopedResource({
    rootKey: 'pix',
    scope: '/orders',
    uri: '/pix',
  }),
  ResetPassword: new ResetPassword(),
  State: new Resource({ rootKey: 'state', uri: '/states' }),
  StoreCredit: new ScopedResource({
    rootKey: 'store-credits',
    scope: '/users',
    uri: '/store-credits',
  }),
  User: new User({ rootKey: 'user', uri: '/users' }),
  UserOrder: new ScopedResource({
    rootKey: 'order',
    scope: '/users',
    uri: '/orders',
  }),
  UserStoreCredit: new UserStoreCredit({
    rootKey: 'store_credit',
    scope: '/users',
    uri: '/store-credits-total',
  }),
};

export default API;
