/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-return-assign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import API from 'api';

import ActivationAccountInstructions from 'views/authentication/ActivationAccountInstructions';
import Loading from 'components/loading/Loading';

import { useAuth } from 'contexts/AuthContext';
import { getQueryParameter } from 'lib/query-string';

import 'views/authentication/styles.scss';

const Login = ({
  returnTo,
  onSignUpClick,
  onForgotPasswordClick,
  onEmailChange,
  initialEmail,
}) => {
  const { login, currentUser } = useAuth();

  const [email, setEmail] = useState(initialEmail || '');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resendActivationMode, setResendActivationMode] = useState(false);
  const [showActivationInstructions, setShowActivationInstructions] =
    useState(false);

  const [loading, setLoading] = useState(true);

  const returnUrl = () => getQueryParameter('url') || returnTo || '/';

  useEffect(() => {
    // Add focus to the email input field on load
    const emailInput = document.querySelector('[data-email-input]');
    if (emailInput) {
      emailInput.focus();
    }

    const redirectReason = getQueryParameter('reason');
    const hasError = errorMessage.length > 0;
    if (redirectReason === 'session' && !hasError) {
      setErrorMessage('Sua sessão expirou, faça login novamente');
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (currentUser) {
    window.location.href = returnUrl();
    return null;
  }

  const onLoginErrorHandler = (e) => {
    setLoading(false);

    if (e.response.status === 401 || e.response.status === 404) {
      setErrorMessage('Email ou senha incorretos');
    } else if (e.response.status === 403) {
      setErrorMessage(
        'Você precisa ativar sua conta antes de continuar. Não recebeu o email? Podemos reenviá-lo para você.',
      );
      setResendActivationMode(true);
    } else {
      setErrorMessage('Não foi possível fazer login');
    }
  };

  const handleSubmit = () => {
    setLoading(true);

    login(email, password)
      .then((status) => {
        if (status.loggedIn) {
          setTimeout(() => (window.location.href = returnUrl()), 800);
        } else {
          onLoginErrorHandler(status.error);
        }
      })
      .catch(() => {
        setErrorMessage('Não foi possível fazer o login');
      });
  };

  const signUpLink = () => {
    if (onSignUpClick) {
      return (
        <span className="link-appearance" onClick={onSignUpClick}>
          {' '}
          Cadastre-se
        </span>
      );
    }

    return <Link to="/signup"> Cadastre-se</Link>;
  };

  const forgotPasswordLink = () => {
    if (onForgotPasswordClick) {
      return (
        <span className="link-appearance" onClick={onForgotPasswordClick}>
          {' '}
          Esqueceu a senha?
        </span>
      );
    }
    return (
      <Link to={`/forgot-password?email=${email}`}>Esqueceu a senha?</Link>
    );
  };

  const resendActivationAccountEmail = () => {
    API.Account.resendAccountActivationEmail(email).then(() => {
      setShowActivationInstructions(true);
    });
  };

  if (loading) {
    return <Loading />;
  }

  if (showActivationInstructions) {
    return (
      <div className="row justify-content-md-center mt-3 vh-100 mt-5">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <ActivationAccountInstructions
                title="Email reenviado"
                email={email}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row justify-content-md-center mt-3 vh-100 mt-5">
      <div className="col col-md-4 d-xs-none"></div>
      <div className="col-md-4">
        <div className="card">
          <div className="card-body">
            <img
              className="mx-auto d-block pb-4"
              style={{ width: '15%' }}
              src="https://res.cloudinary.com/makemydate/image/upload/v1638294830/Logo/android-chrome-192x192_opzoec.png"
              alt="logo"
            />
            <h2 className="text-center mb-4">Entrar</h2>

            {errorMessage.length > 0 && (
              <div
                className="alert alert-danger border-radius-5"
                data-testid="fail-alert"
                dangerouslySetInnerHTML={{ __html: errorMessage }}
              ></div>
            )}

            <section className="form-group" id="email">
              <label>Email</label>
              <input
                className="form-control"
                data-email-input
                data-testid="login-email"
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (onEmailChange && typeof onEmailChange === 'function') {
                    onEmailChange(e.target.value);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    document.querySelector('#password input').focus();
                  }
                }}
                type="email"
                value={email}
              />
            </section>

            {!resendActivationMode && (
              <>
                <section className="form-group" id="password">
                  <label>Senha</label>
                  <input
                    className="form-control"
                    data-testid="login-password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit();
                      }
                    }}
                    type="password"
                    value={password}
                  />
                </section>

                <button
                  className="w-100 btn btn-primary"
                  data-testid="login-button"
                  disabled={loading}
                  onClick={handleSubmit}
                  type="button"
                >
                  Entrar
                </button>

                <div className="w-100 text-center mt-3">
                  {forgotPasswordLink()}
                </div>
              </>
            )}

            {resendActivationMode && (
              <button
                disabled={loading}
                className="w-100 btn btn-primary"
                type="button"
                onClick={resendActivationAccountEmail}
              >
                Reenviar email de ativação
              </button>
            )}
          </div>
        </div>
        {!resendActivationMode && (
          <div className="w-100 text-center mt-2">
            Precisa de uma conta? {signUpLink()}
          </div>
        )}

        {resendActivationMode && (
          <div
            className="w-100 text-center mt-2 pointer"
            onClick={() => {
              setResendActivationMode(false);
              setErrorMessage('');
            }}
          >
            Voltar para o login
          </div>
        )}
      </div>
      <div className="col col-md-4"></div>
    </div>
  );
};

export default Login;
