/* eslint-disable no-return-assign */
import React from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'shards-react';

import EstadosSelect from 'components/estados-select/EstadosSelect';
import { Config } from 'lib/config';
import { maskCPF, maskZipCode } from 'lib/masks';
import { validateCPF } from 'lib/validators';

import 'views/PreBooking/components/styles.scss';

const UserData = ({
  userName,
  setUserName,
  userEmail,
  setUserEmail,
  userCpf,
  setUserCpf,
  significantOtherName,
  setSignificantOtherName,
  significantOtherEmail,
  setSignificantOtherEmail,
  significantOtherCpf,
  setSignificantOtherCpf,
  validUserName,
  validUserEmail,
  validUserCpf,
  setValidUserCpf,
  validSignificantOtherName,
  validSignificantOtherCpf,
  setValidSignificantOtherCpf,
  validStreetAddress,
  validAddressNumber,
  validCity,
  validStateId,
  validZipCode,
  streetAddress,
  setStreetAddress,
  addressNumber,
  addressComplement,
  setAddressNumber,
  setAddressComplement,
  city,
  setCity,
  stateId,
  setStateId,
  zipCode,
  setZipCode,
}) => (
  <Card className="user-data-component">
    <CardHeader className="border-bottom">
      <h5 className="m-0">Seus dados</h5>
    </CardHeader>
    <CardBody>
      <Col sm="12" className="mb-3">
        <Row>
          <Col sm="12" className="mb-3">
            <p className="card-text d-inline-block mb-3">
              <strong>
                Antes de prosseguir, precisamos de algumas informações.
              </strong>
            </p>
          </Col>
          <Col
            sm="12"
            md="6"
            lg="4"
            style={{
              borderLeft: '1px solid #ccc',
              marginBottom: '20px',
              marginTop: '20px',
            }}
          >
            <h5>Sobre você</h5>
            <Row>
              <Col>
                <div className="form-floating m-2">
                  <h6>Nome*</h6>
                  <input
                    className={`form-control ${
                      validUserName ? '' : 'required'
                    }`}
                    type="text"
                    placeholder="Coloque o seu nome"
                    value={userName}
                    disabled
                    onChange={(e) => setUserName(e.currentTarget.value)}
                    data-testid="user-name"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-floating m-2">
                  <h6>E-mail*</h6>
                  <input
                    className={`form-control ${
                      validUserEmail ? '' : 'required'
                    }`}
                    type="email"
                    placeholder="nome@exemplo.com"
                    value={userEmail}
                    disabled
                    onChange={(e) => setUserEmail(e.currentTarget.value)}
                    data-testid="user-email"
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="form-floating m-2">
                  <h6>CPF*</h6>
                  <input
                    className={`form-control ${validUserCpf ? '' : 'required'}`}
                    type="text"
                    placeholder="xxx.xxx.xxx-xx"
                    value={userCpf}
                    disabled
                    onChange={(e) => setUserCpf(e.currentTarget.value)}
                    maxLength={14}
                    onBlur={(e) => {
                      setValidUserCpf(validateCPF(e.currentTarget.value));
                    }}
                    onKeyPress={(e) =>
                      (e.currentTarget.value = maskCPF(e.currentTarget.value))
                    }
                    onPaste={(e) => {
                      if (!Config.allowPasteCPF) {
                        e.preventDefault();
                      }
                    }}
                    data-testid="user-cpf"
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            sm="12"
            md="6"
            lg="4"
            style={{
              borderLeft: '1px solid #ccc',
              marginBottom: '20px',
              marginTop: '20px',
            }}
          >
            <h5>Dados da sua companhia</h5>
            <Row>
              <Col>
                <div className="form-floating m-2">
                  <h6>Nome*</h6>
                  <input
                    className={`form-control ${
                      validSignificantOtherName ? '' : 'required'
                    }`}
                    type="text"
                    placeholder="Coloque o nome da sua companhia"
                    value={significantOtherName}
                    onChange={(e) =>
                      setSignificantOtherName(e.currentTarget.value)
                    }
                    data-testid="significant-other-name"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-floating m-2">
                  <div className="d-flex">
                    <h6>E-mail</h6>
                    <p className="mb-0 pl-1">
                      <small>
                        <em>(opcional)</em>
                      </small>
                    </p>
                  </div>
                  <input
                    className="form-control"
                    type="email"
                    placeholder="nome@exemplo.com"
                    value={significantOtherEmail}
                    onChange={(e) =>
                      setSignificantOtherEmail(e.currentTarget.value)
                    }
                    data-testid="significant-other-email"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-floating m-2">
                  <div className="d-flex">
                    <h6>CPF</h6>
                    <p className="mb-0 pl-1">
                      <small>
                        <em>(opcional)</em>
                      </small>
                    </p>
                  </div>
                  <input
                    className={`form-control ${
                      validSignificantOtherCpf ? '' : 'required'
                    }`}
                    type="text"
                    placeholder="xxx.xxx.xxx-xx"
                    value={significantOtherCpf}
                    onChange={(e) =>
                      setSignificantOtherCpf(e.currentTarget.value)
                    }
                    maxLength={14}
                    onBlur={(e) => {
                      // Significant other CPF is not required. We are
                      // validating it only if it is filled
                      if (e.currentTarget.value !== '') {
                        setValidSignificantOtherCpf(
                          validateCPF(e.currentTarget.value, true),
                        );
                      } else {
                        setValidSignificantOtherCpf(true);
                      }
                    }}
                    onKeyPress={(e) =>
                      (e.currentTarget.value = maskCPF(e.currentTarget.value))
                    }
                    data-testid="significant-other-cpf"
                  />
                </div>
                <p
                  className="card-text text-muted d-inline-block my-3"
                  style={{ fontSize: '0.875rem' }}
                >
                  O e-mail e o CPF da sua companhia são utilizados apenas para
                  procedimentos internos. Sua companhia{' '}
                  <strong>não receberá</strong> nenhum tipo de e-mail da Make My
                  Date.
                </p>
              </Col>
            </Row>
          </Col>
          <Col
            sm="12"
            md="12"
            lg="4"
            style={{
              borderLeft: '1px solid #ccc',
              marginBottom: '20px',
              marginTop: '20px',
            }}
          >
            <h5>Endereço</h5>
            <Row>
              <Col>
                <div className="form-floating m-2">
                  <h6>Logradouro*</h6>
                  <input
                    className={`form-control ${
                      validStreetAddress ? '' : 'required'
                    }`}
                    type="text"
                    placeholder="Rua/avenida/travessa..."
                    value={streetAddress}
                    onChange={(e) => setStreetAddress(e.currentTarget.value)}
                    data-testid="address-street"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md="5">
                <div className="form-floating m-2">
                  <h6>Número*</h6>
                  <input
                    className={`form-control ${
                      validAddressNumber ? '' : 'required'
                    }`}
                    type="text"
                    placeholder="Número"
                    value={addressNumber}
                    onChange={(e) => setAddressNumber(e.currentTarget.value)}
                    data-testid="address-number"
                  />
                </div>
              </Col>
              <Col sm="12" md="7">
                <div className="form-floating m-2">
                  <h6>Complemento</h6>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Complemento"
                    value={addressComplement}
                    onChange={(e) =>
                      setAddressComplement(e.currentTarget.value)
                    }
                    data-testid="address-complement"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-floating m-2">
                  <h6>Cidade*</h6>
                  <input
                    className={`form-control ${validCity ? '' : 'required'}`}
                    type="text"
                    placeholder="Cidade"
                    value={city}
                    onChange={(e) => setCity(e.currentTarget.value)}
                    data-testid="address-city"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="form-floating m-2">
                  <h6>CEP*</h6>
                  <input
                    className={`form-control ${validZipCode ? '' : 'required'}`}
                    type="text"
                    placeholder="CEP"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.currentTarget.value)}
                    onKeyUp={(e) =>
                      (e.currentTarget.value = maskZipCode(
                        e.currentTarget.value,
                      ))
                    }
                    data-testid="address-zip-code"
                  />
                </div>
              </Col>
              <Col>
                <div className="form-floating m-2">
                  <h6>Estado*</h6>
                  <EstadosSelect
                    klass={validStateId ? '' : 'required'}
                    currentStateId={stateId}
                    onChange={(e) => {
                      setStateId(e.currentTarget.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="mb-3">
            <br />
            <br />
            <p className="card-text d-inline-block mb-3">
              Os campos marcados com um asterisco (*) são obrigatórios.
            </p>
          </Col>
        </Row>
      </Col>
    </CardBody>
  </Card>
);

export default UserData;
