import API from '../api';

export const redirectToPaymentPage = (order, paymentMethod, options = {}) => {
  if (paymentMethod === 'pix') {
    redirectToPix(order);
  } else if (paymentMethod === 'stripe') {
    redirectToPaymentProcessorCheckout(order, options);
  }
};

export const redirectToPix = (order) => {
  window.location.href = `/pix?ref=${order.id}`;
};

export const redirectToPaymentProcessorCheckout = (order, options = {}) => {
  const { errorCallback, beforeCallback } = options;

  if (beforeCallback && typeof beforeCallback === 'function') {
    beforeCallback();
  }

  const getCheckoutUrl = (orderId) =>
    API.Payment.newResource(orderId, window.location.href);

  getCheckoutUrl(order.id)
    .then((response) => {
      if (response !== null) {
        window.location.href = response.data.checkout_url;
      }
    })
    .catch(() => {
      if (errorCallback && typeof errorCallback === 'function') {
        errorCallback();
      }
    });
};
