import { DEFAULT_DATE_IMAGE } from './constants';

export const showImage = (order) => showDateImage(order.date);

export const showDateImage = (date) => {
  if (date.image_url) {
    return date.image_url;
  }

  return DEFAULT_DATE_IMAGE;
};
