/* eslint-disable react/no-array-index-key */
/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from 'react';
import { Container, Row, Col, Button } from 'shards-react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import API from 'api';

import ActivationAccountWarning from 'components/activation-account-warning/ActivationAccountWarning';
import DateListItem from 'components/dates/DateListItem';
import Loading from 'components/loading/Loading';
import PageTitle from 'components/common/PageTitle';
import { buildTitle } from 'lib/title';
import { Config } from 'lib/config';
import { useAuth } from 'contexts/AuthContext';

const DatesList = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  document.title = buildTitle('Seus dates');

  const fetchUserOrders = async (userId) => {
    const response = await API.UserOrder.all(userId, {
      order_by: 'inserted_at:desc',
      page_size: 999,
    });

    const { data: body } = response;
    return body.data;
  };

  const {
    data: orders,
    isLoading,
    isError,
    refetch,
  } = useQuery(['user-orders', currentUser.id], () =>
    fetchUserOrders(currentUser.id),
  );

  if (isError) {
    logout();
    navigate('/login');
  }

  if (!orders || isLoading) {
    return <Loading />;
  }

  return (
    <Container fluid className="main-content-container px-4">
      <ActivationAccountWarning />

      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Seus Dates"
          subtitle="Pedidos anteriores"
          className="text-sm-left"
        />
      </Row>

      <Row>
        <Col lg="3" className="mb-4">
          <Button
            href={`${Config.storeUrl}/dates`}
            size="lg"
            block
            theme="white"
            className="mb-2 mr-1"
          >
            Adicionar date
          </Button>
        </Col>
      </Row>

      <Row>
        {orders.length === 0 && (
          <Col lg="12" sm="12" className="mb-4">
            <div className="no-dates">
              <div className="no-dates-content">
                <h2>:(</h2>
                <h4>Parece que você ainda não tem pedidos de dates</h4>
                <Col lg="5" className="m-3">
                  <Button
                    href={`${Config.storeUrl}/makemydate`}
                    size="lg"
                    block
                    theme="primary"
                    className="mb-2 mr-1"
                  >
                    Peça aqui o seu!
                  </Button>
                </Col>
              </div>
            </div>
          </Col>
        )}

        {orders.map((order, idx) => (
          <Col
            lg="12"
            sm="12"
            className="mb-4"
            key={idx}
            data-testid={`date-index-${idx}`}
          >
            <DateListItem order={order} loadOrders={() => refetch()} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default DatesList;
