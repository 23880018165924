/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Collapse,
} from 'shards-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import API from 'api';

import AdditionalInfo from 'views/DatesList/details/AdditionalInfo';
import BackButton from 'components/navigation/back-button/BackButton';
import DateDetailsTimeline from 'views/DatesList/DateDetailsTimeline';
import Loading from 'components/loading/Loading';
import PageTitle from 'components/common/PageTitle';
import RequestChange from 'views/DatesList/RequestChange';

import { BADGE_INFO } from 'lib/badge-info';
import { buildTitle } from 'lib/title';
import { buildUrl } from 'lib/url';
import { formatDate, getTimeFromDate } from 'lib/date-utils';
import { hideCPF } from 'lib/masks';
import { showDateImage as showImage } from 'lib/date-image';
import { useAuth } from 'contexts/AuthContext';

import 'views/DatesList/styles.scss';
import { Config } from 'lib/config';

const closedOrderStatuses = [
  'pre_booking',
  'completed',
  'didnt_happen',
  'canceled',
];

const DateDetails = () => {
  const { orderNumber } = useParams();
  const [additionalInfoVisible, setAdditionalInfoVisible] = useState(false);
  const [requestChangeVisible, setRequestChangeVisible] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const MySwal = withReactContent(Swal);

  const changeRequestChangeVisible = () => {
    setRequestChangeVisible(!requestChangeVisible);
  };

  const fetchOrder = async (number) => {
    const result = await API.UserOrder.find(currentUser.id, number);
    const { data: body } = result;
    return body.data;
  };

  const {
    data: order,
    isLoading,
    refetch,
    isError,
  } = useQuery(['get-order', orderNumber], () => fetchOrder(orderNumber));

  if (isLoading) {
    return <Loading />;
  }

  if (!order || isError) {
    navigate('/404');
  }

  document.title = buildTitle(order.number);

  const weekDayMessage = () => {
    const weekDays = API.Order.weekDaysString(order);
    const prefix = weekDays.includes(', ')
      ? 'Dias da semana possíveis:'
      : 'Dia da semana:';

    return (
      <>
        <strong>{prefix} </strong>
        {weekDays}
      </>
    );
  };

  const buildMessage = () => {
    if (!order.billing.value_paid) {
      return '';
    }

    if (!order.billing.value_paid_with_store_credits) {
      return `Caso continue, o seu pedido entrará em processo de análise de reembolso para o valor de ${order.billing.formatted.value_paid_with_money}. `;
    }

    if (
      order.billing.value_paid_with_store_credits === order.billing.value_paid
    ) {
      return `Caso continue, o valor em créditos na loja de ${order.billing.formatted.value_paid_with_store_credits} será devolvido para a sua conta. `;
    }

    return (
      `Caso continue, o valor em créditos na loja de ${order.billing.formatted.value_paid_with_store_credits} será devolvido para a sua conta ` +
      `e o valor de ${order.billing.formatted.value_paid_with_money} entrará em processo de análise de reembolso. `
    );
  };

  const confirmCancelation = () => {
    MySwal.fire({
      title: `Tem certeza que quer cancelar o pedido${
        order.number ? ` ${order.number}` : ''
      }?`,
      html: `Esta operação <b>não poderá ser desfeita</b>. ${buildMessage()}`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#c4183c',
      cancelButtonText: 'Não',
      icon: 'warning',
      input: 'text',
      inputPlaceholder: 'Por que você quer cancelar?',
      inputAttributes: {
        autocapitalize: 'off',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const comment = result.value;
        API.Order.cancelOrder(order.id, comment)
          .then(() => {
            Swal.fire('Pedido cancelado', '', 'success');
            refetch();
          })
          .catch(() => {
            Swal.fire('Oops, alguma coisa deu errada', '', 'error');
          });
      }
    });
  };

  const buildLabel = (title, text) => (
    <>
      <strong>{title}: </strong>
      {text}
      <br />
    </>
  );

  const toggleAdditionalInfo = () => {
    setAdditionalInfoVisible(!additionalInfoVisible);
  };

  const showMaskedCPF = (cpf) => {
    if (cpf === '') {
      return '';
    }

    return hideCPF(cpf);
  };

  const showLocation = () => {
    const location = order.option.locations[0];
    if (!location) {
      return null;
    }

    if (order.status !== 'ready') {
      return (
        <p>O local exato será informado após a confirmação de pagamento</p>
      );
    }

    return (
      <>
        <strong>Local: </strong>
        {`${location.address}, ${location.number} ${location.complement || ''}`}
        <br />
        {`${location.city}, ${location.state} ${location.country} ${
          location.zip_code || ''
        }`}
        <br />
        {`${location.reference || ''}`} <br />
      </>
    );
  };

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <BackButton />
      <Row noGutters className="page-header">
        <PageTitle
          sm="4"
          title={order.number}
          subtitle="Detalhes do Pedido"
          className="text-sm-left  pb-4"
        />
        <div className="col-sm-4 d-flex flex-column align-items-center justify-content-end pb-4">
          <span className="text-uppercase page-subtitle text-sm-left text-center text-md-left text-muted">
            <strong>Status</strong>
          </span>
          <div
            className={`${BADGE_INFO[order.status].klass} status-badge`}
            style={
              order.status === 'ready'
                ? { animation: 'green-pulse 2s infinite' }
                : order.status === 'pre_booking'
                ? { animation: 'dark-pulse 2s infinite' }
                : {}
            }
          >
            {BADGE_INFO[order.status].text}
          </div>
        </div>
      </Row>

      <Row>
        <Col lg="12" sm="12" className="mb-4">
          <Card
            small
            className="card-post card-post--aside card-post--1 flex-flow-column h-100"
          >
            <div className="inherit column-mobile border-bottom">
              <div
                className="card-post__image card-img-left-or-top no-border-bottom-left"
                style={{ backgroundImage: `url('${showImage(order.date)}')` }}
              ></div>
              <CardBody>
                <div className="card-text-2">
                  <h5 className="card-title" data-testid="date-title">
                    {order.date.title}
                  </h5>
                  <p className="card-text d-inline-block mb-3">
                    <strong>Opção escolhida:</strong> {order.option.title}
                    <br className="mb-2" />
                    <strong>Valor: </strong>
                    {order.billing.formatted.price_with_discount}
                  </p>
                </div>
                <div className="card-details">
                  <span className="text-muted">
                    <strong>Criado em</strong> {formatDate(order.inserted_at)}
                  </span>

                  {order.status === 'waiting_for_payment' &&
                    order.expires_at && (
                      <>
                        <br />
                        <span className="text-muted">
                          <strong>Expira em</strong>{' '}
                          {formatDate(order.expires_at)}
                        </span>
                      </>
                    )}

                  {order.status === 'completed' && (
                    <>
                      <br />
                      <span className="text-muted">
                        <strong>Pago em</strong> {formatDate(order.paid_at)}
                      </span>
                    </>
                  )}
                </div>
              </CardBody>

              <Col className="card-side-buttons-container">
                {order.status !== 'pre_booking' &&
                  closedOrderStatuses.includes(order.status) && (
                    <Button
                      outline
                      size="sm"
                      theme="secondary"
                      className="mb-2 mr-1 display-flex"
                      onClick={() => {
                        window.location.href = buildUrl(
                          `${Config.storeUrl}/date/agende/${order.date.slug}/${order.option.label}`,
                        );
                      }}
                      data-testid="date-buy-again-button"
                    >
                      <span className="material-icons">replay</span>
                      <p>Pedir de novo</p>
                    </Button>
                  )}

                {!closedOrderStatuses.includes(order.status) && (
                  <Button
                    outline
                    size="sm"
                    theme="primary"
                    className="mb-2 mr-1 display-flex"
                    onClick={changeRequestChangeVisible}
                    data-testid="date-edit-button"
                  >
                    <span className="material-icons">edit</span>
                    <p>Editar</p>
                  </Button>
                )}

                {!order.number && order.status === 'pre_booking' && (
                  <Button
                    outline
                    size="sm"
                    theme="primary"
                    className="mb-2 mr-1 display-flex"
                    disabled={order.status === 'canceled'}
                    onClick={() => {
                      const preBookingUrl = `/pre-booking?ref=${order.id}`;
                      navigate(preBookingUrl);
                    }}
                    data-testid="date-continue-button"
                  >
                    <span className="material-icons">arrow_forward</span>
                    <p>Continuar</p>
                  </Button>
                )}

                {order.status === 'waiting_for_payment' && (
                  <Button
                    disabled={
                      !order.number ||
                      closedOrderStatuses.includes(order.status)
                    }
                    outline
                    size="sm"
                    theme="warning"
                    className="mb-2 mr-1 display-flex"
                    onClick={() => {
                      const yourReservationUrl = `/your-reservation?ref=${order.id}`;
                      navigate(yourReservationUrl);
                    }}
                    data-testid="date-edit-button"
                  >
                    <span className="material-icons">attach_money</span>
                    <p>Pagar</p>
                  </Button>
                )}
              </Col>
            </div>

            <CardBody
              small="true"
              className="d-lg-none card-bottom-buttons-container"
            >
              <Row className="justify-content-around">
                {order.status !== 'pre_booking' &&
                  closedOrderStatuses.includes(order.status) && (
                    <Button
                      outline
                      size="sm"
                      theme="secondary"
                      className="my-2 display-flex"
                      onClick={() => {
                        window.location.href = buildUrl(
                          `${Config.storeUrl}/date/agende/${order.date.slug}/${order.option.label}`,
                        );
                      }}
                      data-testid="date-buy-again-button"
                    >
                      <span className="material-icons">replay</span>
                      <p>Pedir de novo</p>
                    </Button>
                  )}

                {!closedOrderStatuses.includes(order.status) && (
                  <Button
                    outline
                    size="sm"
                    theme="primary"
                    className="my-2 display-flex"
                    onClick={changeRequestChangeVisible}
                    data-testid="date-edit-button"
                  >
                    <span className="material-icons">edit</span>
                    <p>Editar</p>
                  </Button>
                )}

                {!order.number && order.status === 'pre_booking' && (
                  <Button
                    outline
                    size="sm"
                    theme="primary"
                    className="my-2 display-flex"
                    disabled={order.status === 'canceled'}
                    onClick={() => {
                      const preBookingUrl = `/pre-booking?ref=${order.id}`;
                      navigate(preBookingUrl);
                    }}
                    data-testid="date-continue-button"
                  >
                    <span className="material-icons">arrow_forward</span>
                    <p>Continuar</p>
                  </Button>
                )}

                {order.status === 'waiting_for_payment' && (
                  <Button
                    disabled={
                      !order.number ||
                      closedOrderStatuses.includes(order.status)
                    }
                    outline
                    size="sm"
                    theme="warning"
                    className="my-2 display-flex"
                    onClick={() => {
                      const yourReservationUrl = `/your-reservation?ref=${order.id}`;
                      navigate(yourReservationUrl);
                    }}
                    data-testid="date-edit-button"
                  >
                    <span className="material-icons">attach_money</span>
                    <p>Pagar</p>
                  </Button>
                )}
              </Row>
            </CardBody>

            <section
              className={`${
                requestChangeVisible ? 'show-footer' : 'hide-footer'
              }`}
            >
              <RequestChange
                order={order}
                onClose={changeRequestChangeVisible}
              />
            </section>

            {order.status === 'placed' && (
              <CardBody>
                <div className="order-placed-info">
                  <div className="order-placed-info-icon">
                    <span className="material-icons">info_outline</span>
                  </div>
                  <div className="order-placed-info-text">
                    <p>
                      O seu pedido está pré-agendado, isso significa que estamos
                      analisando o seu pedido para fazer o agendamento seguindo
                      as suas preferências escolhidas. Em breve você receberá um
                      e-mail com a data e horário da sua reserva, assim como um
                      link para o pagamento.
                    </p>
                  </div>
                </div>
              </CardBody>
            )}

            <CardBody className="border-bottom">
              {!order.scheduled_for && (
                <>
                  <h6>Preferências para o agendamento</h6>
                  <p className="card-text d-inline-block mb-3">
                    <strong>A partir de </strong>
                    {formatDate(order.start_date)}
                    <br className="mb-2" />
                    <strong>Entre </strong>
                    {order.from_time} e {order.to_time}
                    <br className="mb-2" />
                    {weekDayMessage()}
                  </p>
                </>
              )}

              {order.scheduled_for && (
                <>
                  <h6>Agendamento</h6>
                  <p className="card-text d-inline-block mb-3">
                    <strong>Agendado para </strong>
                    {formatDate(order.start_date)} às{' '}
                    {getTimeFromDate(order.scheduled_for)}
                    <br className="mb-2" /> <br />
                    {showLocation()}
                    {order.billing.balance_due === 0 &&
                      'Este pedido já está pago'}
                    {order.billing.balance_due > 0 &&
                      `Valor a ser pago: ${order.billing.formatted.balance_due}`}
                  </p>
                </>
              )}
            </CardBody>
            {order && (
              <CardBody className="border-bottom">
                <h6>Linha do tempo</h6>

                <DateDetailsTimeline order={order} />
              </CardBody>
            )}

            <CardBody className="border-bottom">
              <h6>Você vai com</h6>

              <div>
                {buildLabel('Nome', order.significant_other_name)}
                {order.significant_other_email &&
                  buildLabel('Email', order.significant_other_email)}
                {order.significant_other_cpf &&
                  buildLabel('CPF', showMaskedCPF(order.significant_other_cpf))}
              </div>

              <br />
              <div
                className="d-inline-flex pt-3 pointer"
                onClick={toggleAdditionalInfo}
              >
                <h6>Informações adicionais</h6>
                <span
                  className={`material-icons text-fiord-blue dropdown-arrow-icon
                  ${additionalInfoVisible ? 'inactive-icon' : ''}`}
                >
                  keyboard_arrow_down
                </span>
                <span
                  className={`material-icons text-fiord-blue dropdown-arrow-icon
                  ${additionalInfoVisible ? '' : 'inactive-icon'}`}
                >
                  keyboard_arrow_up
                </span>
              </div>
              <Collapse open={additionalInfoVisible}>
                <AdditionalInfo order={order} />
              </Collapse>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mx-auto mt-3 mb-5">
        {!closedOrderStatuses.includes(order.status) &&
          order.status !== 'canceled' && (
            <Button
              outline
              size="sm"
              theme="danger"
              className="mb-2 mr-1 display-flex solo-button"
              onClick={confirmCancelation}
              data-testid="date-cancel-button"
            >
              <span className="material-icons">close</span>
              <p>Cancelar pedido</p>
            </Button>
          )}
      </Row>
    </Container>
  );
};

export default DateDetails;
