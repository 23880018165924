/* eslint-disable global-require */

import React from 'react';
import { NavbarBrand } from 'shards-react';

import { Config } from 'lib/config';

const MainLogo = ({ alignLogoLeft, extraClass, hideLogoText }) => (
  <NavbarBrand
    className={`w-100 mr-0 ${extraClass}`}
    data-testid="main-logo"
    href={Config.storeUrl}
    style={{ lineHeight: '25px' }}
  >
    <div className={`d-table ${alignLogoLeft ? '' : 'm-auto'}`}>
      <img
        id="main-logo"
        className="d-inline-block align-top mr-1"
        style={{ maxWidth: '25px' }}
        src={require('../../shards-dashboard/images/logo.png')}
        alt="Make My Date"
      />
      {!hideLogoText && (
        <span className="d-none d-md-inline ml-1 h6">Make My Date</span>
      )}
    </div>
  </NavbarBrand>
);

export default MainLogo;
