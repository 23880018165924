/* eslint-disable no-bitwise */
import Resource from '../resource';

export default class Order extends Resource {
  cancelOrder(id, comment) {
    return this.update(id, { status: 'canceled', comment });
  }

  weekDaysString(order) {
    const days = {
      monday: { text: 'segunda', binary: '0100000' },
      tuesday: { text: 'terça', binary: '0010000' },
      wednesday: { text: 'quarta', binary: '0001000' },
      thursday: { text: 'quinta', binary: '0000100' },
      friday: { text: 'sexta', binary: '0000010' },
      saturday: { text: 'sábado', binary: '0000001' },
      sunday: { text: 'domingo', binary: '1000000' },
    };

    const includeDay = (weekDay) => {
      const weekDayData = days[weekDay];
      const intFromBinary = parseInt(weekDayData.binary, 2);
      return (order.week_days & intFromBinary) === intFromBinary
        ? weekDayData.text
        : null;
    };

    return [
      includeDay('sunday'),
      includeDay('monday'),
      includeDay('tuesday'),
      includeDay('wednesday'),
      includeDay('thursday'),
      includeDay('friday'),
      includeDay('saturday'),
    ]
      .filter((n) => n)
      .join(', ');
  }
}
