import React from 'react';

import './styles.scss';

// Source: https://www.bootdey.com/snippets/view/timeline-events#html
const Timeline = ({
  timelineTitle,
  items,
  eventTitle,
  eventDescription,
  eventDate,
  insideCard,
}) => {
  const buildEvent = (item) => {
    const title = eventTitle && eventTitle(item);
    const description = eventDescription && eventDescription(item);

    return (
      <li key={item.id} className="event" data-date={eventDate(item)}>
        <p className="event-date">{eventDate(item)}</p>
        {title && <h3>{eventTitle(item)}</h3>}
        {description}
      </li>
    );
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className={`${insideCard ? 'card' : ''}`}>
            <div className={`${insideCard ? 'card-body' : ''}`}>
              {timelineTitle && <h6 className="card-title">{timelineTitle}</h6>}
              <div id="content">
                <ul className="timeline">
                  {items.map((item) => buildEvent(item))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
