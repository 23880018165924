/* eslint-disable global-require */
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  FormCheckbox,
  Button,
  CardBody,
} from 'shards-react';

import StoreCredits from 'components/user-profile/StoreCredits';
import { avatarUrl } from 'lib/avatar';

import './styles.scss';

const UserDetails = ({ userDetails, saveProfile }) => {
  const [userInfo, setUserInfo] = useState({});
  const [avatarChooserVisible, setAvatarChooserVisible] = useState(false);

  const changeAvatarChooserVisible = () => {
    setAvatarChooserVisible(!avatarChooserVisible);
  };

  useEffect(() => {
    setUserInfo(userDetails);
  }, [userDetails]);

  if (userInfo === {}) {
    return null;
  }

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom text-center">
        <span
          className={`
            ${avatarChooserVisible ? 'd-none' : ''}
            rounded-circle material-icons edit-icon-overlay-bottom-right`}
          onClick={changeAvatarChooserVisible}
        >
          edit
        </span>

        {userInfo.avatar && (
          <div className="mb-3 mx-auto">
            <img
              className="rounded-circle avatar"
              src={userInfo.avatar}
              alt={userDetails.name}
              title={userInfo.avatar}
              width="110"
            />
          </div>
        )}

        <h4 className="mb-0">{userDetails.first_name}</h4>
      </CardHeader>

      <CardBody
        className={`${avatarChooserVisible ? 'border-bottom' : 'd-none'}`}
      >
        <div className="d-flex justify-content-between">
          <strong>Escolha seu avatar</strong>
          <span
            className="material-icons pointer"
            onClick={changeAvatarChooserVisible}
          >
            close
          </span>
        </div>
        <div className="avatar-list mr-2">
          {[...Array(12)].map((x, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className="avatar">
              <button
                href=""
                className="avatar-button"
                onClick={() => {
                  setUserInfo({
                    ...userInfo,
                    avatar: avatarUrl(i),
                  });
                }}
              >
                <img
                  className="rounded-circle avatar-list-item"
                  src={avatarUrl(i)}
                  alt=""
                  width="90"
                />
              </button>
            </div>
          ))}
        </div>
      </CardBody>

      <ListGroup flush>
        <StoreCredits />

        <ListGroupItem className="p-4 border-top">
          <strong className="text-muted d-block mb-2">Formas de contato</strong>
          <FormCheckbox
            toggle
            small
            checked={userInfo.contact_by_email}
            onChange={() => {
              setUserInfo({
                ...userInfo,
                contact_by_email: !userInfo.contact_by_email,
              });
            }}
          >
            Email*
          </FormCheckbox>
          <FormCheckbox
            toggle
            small
            checked={userInfo.contact_by_sms}
            onChange={() => {
              setUserInfo({
                ...userInfo,
                contact_by_sms: !userInfo.contact_by_sms,
              });
            }}
          >
            SMS
          </FormCheckbox>
          <FormCheckbox
            toggle
            small
            checked={userInfo.contact_by_whatsapp}
            onChange={() => {
              setUserInfo({
                ...userInfo,
                contact_by_whatsapp: !userInfo.contact_by_whatsapp,
              });
            }}
          >
            WhatsApp
          </FormCheckbox>
          <p className="text-justify">
            <i>
              *Para garantir a melhor experiência possível, os emails referentes
              ao processo de contratação dos dates serão enviados independente
              das preferências acima.
            </i>
          </p>
          <div className="update-info-button">
            <Button
              className="mt-3"
              theme="accent"
              onClick={() => {
                saveProfile(userInfo);
              }}
            >
              Atualizar preferências
            </Button>
          </div>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

export default UserDetails;
