import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from 'shards-react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import API from 'api';

import BackButton from 'components/navigation/back-button/BackButton';
import Loading from 'components/loading/Loading';
import PageTitle from 'components/common/PageTitle';
import PaymentSummary from 'views/Reservation/PaymentSummary';
import ReservationDetails from 'views/Reservation/ReservationDetails';
import { buildTitle } from 'lib/title';
import { getQueryParameter } from 'lib/query-string';
import { redirectToPaymentPage } from 'lib/payment';
import { useAuth } from 'contexts/AuthContext';

import 'views/Reservation/styles.scss';

const Reservation = () => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const MySwal = withReactContent(Swal);

  const { currentUser } = useAuth();

  const orderId = getQueryParameter('ref');
  const navigate = useNavigate();

  document.title = buildTitle('Reserva confirmada');

  const fetchOrder = async (id) => {
    const result = await API.Order.find(id);
    const { data: body } = result;
    return body.data;
  };

  const {
    data: order,
    isLoading,
    refetch,
  } = useQuery(['get-order', orderId], () => fetchOrder(orderId));

  const fetchUserStoreCreditsTotal = async (userId) => {
    const result = await API.UserStoreCredit.all(userId);
    return result.data;
  };

  const {
    data: userStoreCreditsTotal,
    isLoading: isLoadingUserStoreCreditsTotal,
    refetch: refetchUserStoreCreditsTotal,
  } = useQuery(['user-store-credits-total', currentUser.id], () =>
    fetchUserStoreCreditsTotal(currentUser.id),
  );

  if (!order || isLoading || isRedirecting || isLoadingUserStoreCreditsTotal) {
    return <Loading />;
  }

  if (
    order !== null &&
    order.status === 'waiting_for_payment' &&
    order.user.id === currentUser.id
  ) {
    if (paymentMethod === '') {
      setPaymentMethod(order.payment_method || 'pix');
    }
  } else {
    navigate(`/your-dates/${order.number}`);
  }

  const goToPaymentHandler = () => {
    setIsRedirecting(true);
    const options = {
      errorCallback: () => {
        setIsRedirecting(false);
        Swal.fire(
          'Não conseguimos redirecionar você para o pagamento, mas não se preocupe, recebemos o seu pedido e vamos entrar em contato por email em breve.',
          '',
          'error',
        );
      },
    };

    if (paymentMethod !== order.payment_method) {
      const payload = {
        status: 'waiting_for_payment',
        payment_method: paymentMethod,
      };

      API.Order.update(order.id, payload).then(() =>
        redirectToPaymentPage(order, paymentMethod, options),
      );
    } else {
      redirectToPaymentPage(order, paymentMethod, options);
    }
  };

  const applyStoreCredits = () => {
    API.OrderStoreCredit.create(order.id).then(() => {
      refetch();
      refetchUserStoreCreditsTotal();
    });
  };

  const removeStoreCredits = () => {
    API.OrderStoreCredit.destroy(order.id, '').then(() => {
      refetch();
      refetchUserStoreCreditsTotal();
    });
  };

  const buildMessage = () => {
    if (!order.billing.value_paid) {
      return '';
    }

    if (!order.billing.value_paid_with_store_credits) {
      return `Caso continue, o seu pedido entrará em processo de análise de reembolso para o valor de ${order.billing.formatted.value_paid_with_money}. `;
    }

    if (
      order.billing.value_paid_with_store_credits === order.billing.value_paid
    ) {
      return `Caso continue, o valor em créditos na loja de ${order.billing.formatted.value_paid_with_store_credits} será devolvido para a sua conta. `;
    }

    return (
      `Caso continue, o valor em créditos na loja de ${order.billing.formatted.value_paid_with_store_credits} será devolvido para a sua conta ` +
      `e o valor de ${order.billing.formatted.value_paid_with_money} entrará em processo de análise de reembolso. `
    );
  };

  const onCancelOrderHandle = () => {
    MySwal.fire({
      title: `Tem certeza que quer cancelar o pedido ${order.number}?`,
      html: `Esta operação <b>não poderá ser desfeita</b>. ${buildMessage()}`,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#c4183c',
      cancelButtonText: 'Não',
      icon: 'warning',
      input: 'text',
      inputPlaceholder: 'Por que você quer cancelar?',
      inputAttributes: {
        autocapitalize: 'off',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const cancelComment = result.value;
        API.Order.cancelOrder(order.id, cancelComment)
          .then(() => {
            Swal.fire({
              title: 'Sucesso',
              text: 'Pedido cancelado',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              navigate('/your-dates');
            });
          })
          .catch(() => {
            Swal.fire(
              'Não foi possível cancelar. Por favor, entre em contato conosco.',
              '',
              'error',
            );
          });
      }
    });
  };

  const completeWithoutPayment = () => {
    API.Order.update(order.id, {
      status: 'ready',
      comment: 'Pedido pago com créditos na loja.',
    }).then(() => {
      Swal.fire({
        title: 'Sucesso',
        text: 'Seu pedido está pago, agora é só esperar.',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate(`/your-dates/${order.number}`);
      });
    });
  };

  const toCamelCase = (str) =>
    str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) {
        return '';
      }

      return index === 0
        ? match.toUpperCase()
        : match.toUpperCase()[0] + match.slice(1);
    });

  // TODO: Do we need this fontawesome link here? It's already in the packages
  // however, without this the pix icon does not work. Check how we can fix
  // that.
  return (
    <>
      <link
        href="https://use.fontawesome.com/releases/v6.0.0-beta2/css/all.css"
        rel="stylesheet"
      />

      <Container fluid className="main-content-container px-4 no-min-h">
        <BackButton />
        <Row>
          <Col>
            <Row className="page-header py-4">
              <PageTitle
                title="Sua reserva foi confirmada!"
                subtitle="Agora vai"
                md="12"
                className="ml-sm-auto mr-sm-auto"
              />
            </Row>

            <Row className="m-0">
              <Col className="mb-4 order-number-highlight">
                <h5 className="m-0">Número do pedido: {order.number}</h5>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="8">
            <ReservationDetails order={order} />
          </Col>

          <Col sm="12" md="4">
            <Row>
              <Col className="mb-4">
                <Card>
                  <CardHeader className="border-bottom">
                    <h5 className="m-0">Pagamento</h5>
                  </CardHeader>
                  <CardBody>
                    <Col>
                      <Row>
                        <PaymentSummary
                          order={order}
                          removeStoreCredits={removeStoreCredits}
                        />
                      </Row>

                      {isLoadingUserStoreCreditsTotal && <Loading />}

                      {userStoreCreditsTotal &&
                        userStoreCreditsTotal.total > 0 &&
                        !order.has_store_credits_applied && (
                          <Row className="you-have-credits-section">
                            <Col>
                              <h5 className="card-title">
                                <i className="fas fa-dollar-sign pr-1"></i> Você
                                possui créditos!
                              </h5>

                              <p>
                                Que ótima notícia, você tem{' '}
                                {userStoreCreditsTotal.formatted_total} em
                                créditos. Quer usá-los agora?
                              </p>

                              <Button
                                className="mx-auto"
                                onClick={applyStoreCredits}
                              >
                                Aplicar{' '}
                                {userStoreCreditsTotal.total >
                                order.billing.balance_due
                                  ? order.billing.formatted.balance_due
                                  : userStoreCreditsTotal.formatted_total}
                              </Button>
                            </Col>
                          </Row>
                        )}

                      {order.billing.balance_due > 0 && (
                        <>
                          <Row>
                            <Col>
                              <br />
                              <h5 className="card-title">
                                Método de pagamento:
                              </h5>
                            </Col>
                          </Row>
                          <Row>
                            <div className="payment-method-container">
                              <div
                                className={`payment-method-option ${
                                  paymentMethod === 'stripe' ? 'selected' : ''
                                }`}
                                onClick={() => {
                                  const paymentProcessor =
                                    process.env.REACT_APP_PAYMENT_PROCESSOR;

                                  setPaymentMethod(paymentProcessor);
                                }}
                              >
                                <div className="logo">
                                  <i className="mx-1 far fa-credit-card"></i>
                                  <i className="mx-1 fas fa-barcode"></i>
                                </div>
                                <div className="text">
                                  <p>Cartão ou Boleto</p>
                                </div>
                              </div>
                              <div
                                className={`payment-method-option  ${
                                  paymentMethod === 'pix' ? 'selected' : ''
                                }`}
                                onClick={() => setPaymentMethod('pix')}
                              >
                                <div className="logo">
                                  <i className="fa-brands fa-pix"></i>
                                </div>
                                <div className="text">
                                  <p>PIX</p>
                                </div>
                              </div>
                            </div>
                          </Row>
                          <Row className="mx-auto my-3">
                            <Button
                              pill
                              size="lg"
                              className="mx-auto"
                              onClick={goToPaymentHandler}
                            >
                              Ir para o pagamento
                            </Button>
                          </Row>
                        </>
                      )}

                      {order.billing.balance_due <= 0 && (
                        <Row className="mx-auto my-3">
                          <Button
                            pill
                            size="lg"
                            className="mx-auto"
                            onClick={completeWithoutPayment}
                          >
                            Concluir
                          </Button>
                        </Row>
                      )}
                      {paymentMethod === 'stripe' && (
                        <p className="gateway-info">
                          * O pagamento será processado pela{' '}
                          {toCamelCase(paymentMethod)}, uma facilitadora de
                          pagamentos. A Make My Date não terá acesso aos dados
                          bancários fornecidos e receberá apenas a informação
                          sobre o sucesso ou falha do pagamento.
                        </p>
                      )}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mx-2 mt-3 mb-5">
          <Button
            outline
            theme="danger"
            className="m-2"
            onClick={onCancelOrderHandle}
          >
            Cancelar reserva
          </Button>
        </Row>
      </Container>
    </>
  );
};

export default Reservation;
