import React, { useState } from 'react';
import Swal from 'sweetalert2';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  ListGroup,
  Button,
  FormTextarea,
} from 'shards-react';
import { useNavigate } from 'react-router-dom';

import API from 'api';

import {
  formatDate,
  formatDateWithWeekDay,
  formatDateWithDayAndMonth,
  getTimeFromDate,
} from 'lib/date-utils';
import { showImage } from 'lib/date-image';

const ReservationDetails = ({ order }) => {
  const [comment, setComment] = useState('');
  const [isCommentValid, setIsCommentValid] = useState(true);
  const [requestChangeVisible, setRequestChangeVisible] = useState(false);
  const navigate = useNavigate();

  const changeRequestChangeVisible = () => {
    setRequestChangeVisible(!requestChangeVisible);
  };

  const onChangeStatusHandler = () => {
    if (comment.trim().length === 0) {
      setIsCommentValid(false);
      Swal.fire(
        'Deixe um comentário pra gente com alguma dica pra nos ajudar a encontrar o dia e horário ideal para vocês',
        '',
        'error',
      );
    } else {
      API.Order.update(order.id, {
        status: 'waiting_for_changes',
        comment,
      }).then(() => {
        Swal.fire({
          title: 'Sucesso',
          text: 'Pedido de alteração enviado! Em breve a gente entra em contato com uma nova sugestão de dia e horário para seu date',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          navigate(`/your-dates/${order.number}`);
        });
      });
    }
  };

  return (
    <Row>
      <Col lg="12" sm="12" className="mb-4">
        <Card
          small
          className="card-post card-post--aside card-post--1 flex-flow-column"
        >
          <Row className="payment-instructions">
            <Col>
              <h5 className="card-title title-for-desktop">
                Clique no botão ao lado para ir para a página de pagamento
              </h5>

              <h5 className="card-title title-for-mobile">
                Clique no botão abaixo para ir para a página de pagamento
              </h5>

              <p className="card-text d-inline-block mb-1">
                <strong>
                  Agora que a sua reserva já foi feita, você só precisa realizar
                  o pagamento para confirmá-la. Assim que seu pagamento for
                  confirmado, você receberá um e-mail com todos os detalhes para
                  sua experiência. Aí é só esperar o dia e aproveitar muito!
                </strong>
              </p>
            </Col>
          </Row>
        </Card>
        <hr />
        <Card
          small
          className="card-post card-post--aside card-post--1 flex-flow-column"
        >
          <CardHeader className="border-bottom">
            <h5 className="m-0">Detalhes</h5>
          </CardHeader>

          <div className="inherit column-mobile">
            <ListGroup className="w-100">
              <Row className="p-0 px-3">
                <div
                  className="card-post__image__2 flex-grow-1"
                  style={{ backgroundImage: `url('${showImage(order)}')` }}
                ></div>
                <CardBody>
                  <div className="card-text-2">
                    <h5 className="card-title">{order.date.title}</h5>
                    <p className="card-text d-inline-block mb-3">
                      <strong>Opção escolhida:</strong> {order.option.title}
                      <br className="mb-2" />
                      <strong>Valor: </strong>
                      {order.billing.formatted.price_with_discount}
                    </p>
                  </div>
                  <div className="card-details">
                    <span className="text-muted">
                      <strong>Pré-agendado em</strong>{' '}
                      {formatDate(order.inserted_at)}
                    </span>

                    {order.status === 'completed' && (
                      <>
                        <br />
                        <span className="text-muted">
                          <strong>Pago em</strong> {formatDate(order.paid_at)}
                        </span>
                      </>
                    )}
                  </div>
                </CardBody>
              </Row>
              <Row>
                <CardBody>
                  <Row className="px-3">
                    <Col sm="12" md="8">
                      <div className="card-text-2 date-and-time-container">
                        <h5 className="card-title">Data e hora</h5>

                        <Row className="date-and-time-badge-row">
                          <div className="bg-light date-and-time-badge-container">
                            <h3>
                              {formatDateWithDayAndMonth(order.scheduled_for)}
                            </h3>
                            <h5>
                              {formatDateWithWeekDay(order.scheduled_for)}
                            </h5>
                          </div>
                          <div className="bg-light date-and-time-badge-container">
                            <h5>Horário</h5>
                            <h3>{getTimeFromDate(order.scheduled_for)}</h3>
                          </div>
                        </Row>
                      </div>
                    </Col>
                    <Col sm="12" md="4" className="details-container">
                      <h5 className="card-title">Detalhes escolhidos: </h5>
                      <ul>
                        <li className="mb-1">{order.option.title}</li>
                        {order.comment && (
                          <li className="mb-1">{order.comment}</li>
                        )}
                      </ul>
                    </Col>
                  </Row>
                </CardBody>
              </Row>

              <CardBody className="p-0 px-3">
                <Row className="justify-content-end">
                  <Button
                    pill
                    outline
                    theme="secondary"
                    className={`${requestChangeVisible ? 'd-none' : 'm-3'}`}
                    onClick={changeRequestChangeVisible}
                  >
                    Pedir mudança na reserva
                  </Button>
                </Row>
              </CardBody>
            </ListGroup>
          </div>

          <CardFooter
            className={`${requestChangeVisible ? 'border-top' : 'd-none'}`}
          >
            <h5>Solicitação de alteração</h5>

            <FormTextarea
              className={`mb-3 ${isCommentValid ? '' : 'required'}`}
              placeholder="Escreva aqui sobre a mudança que deseja na sua reserva."
              rows="5"
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
            <Row>
              <Row className="mx-auto my-1">
                <Button
                  pill
                  outline
                  theme="danger"
                  size="lg"
                  className="m-2"
                  onClick={changeRequestChangeVisible}
                >
                  Cancelar
                </Button>
                <Button
                  pill
                  theme="secondary"
                  size="lg"
                  className="m-2"
                  onClick={onChangeStatusHandler}
                >
                  Pedir mudança
                </Button>
              </Row>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};

export default ReservationDetails;
