/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from 'shards-react';
import { useNavigate } from 'react-router-dom';

import API from 'api';

import Loading from 'components/loading/Loading';
import PageTitle from 'components/common/PageTitle';
import UserData from 'views/PreBooking/components/UserData';
import { buildTitle } from 'lib/title';
import { buildUrl } from 'lib/url';
import { formatDate } from 'lib/date-utils';
import { getQueryParameter } from 'lib/query-string';
import { showImage } from 'lib/date-image';
import { useAuth } from 'contexts/AuthContext';
import { validateCPF } from 'lib/validators';
import { Config } from 'lib/config';

const PreBooking = () => {
  const navigate = useNavigate();

  // user attributes
  const { currentUser } = useAuth();
  const [user, setUser] = useState(null);

  // order attributes
  const orderId = getQueryParameter('ref');
  const [order, setOrder] = useState(null);

  // input fields for documents and user data
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userCpf, setUserCpf] = useState('');
  const [significantOtherName, setSignificantOtherName] = useState('');
  const [significantOtherEmail, setSignificantOtherEmail] = useState('');
  const [significantOtherCpf, setSignificantOtherCpf] = useState('');

  // input fields for address
  const [streetAddress, setStreetAddress] = useState('');
  const [addressNumber, setAddressNumber] = useState('');
  const [addressComplement, setAddressComplement] = useState('');
  const [city, setCity] = useState('');
  const [stateId, setStateId] = useState('');
  const [zipCode, setZipCode] = useState('');

  // validation for input fields
  const [validUserName, setValidUserName] = useState(true);
  const [validUserEmail, setValidUserEmail] = useState(true);
  const [validUserCpf, setValidUserCpf] = useState(true);
  const [validSignificantOtherName, setValidSignificantOtherName] =
    useState(true);
  const [validSignificantOtherCpf, setValidSignificantOtherCpf] =
    useState(true);
  const [validStreetAddress, setValidStreetAddress] = useState(true);
  const [validAddressNumber, setValidAddressNumber] = useState(true);
  const [validCity, setValidCity] = useState(true);
  const [validStateId, setValidStateId] = useState(true);
  const [validZipCode, setValidZipCode] = useState(true);

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    async function fetchData() {
      if (orderId) {
        document.title = buildTitle('Pré-agendamento');
        await Promise.all([fetchUserData(), fetchOrderData()]);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!orderId) {
    navigate('/404');
  }

  const fetchUserData = () => {
    API.User.find(currentUser.id)
      .then((response) => {
        const { data: body } = response;
        const userFromBackend = body.data;

        if (userFromBackend !== null) {
          setUser(userFromBackend);
          setUserName(
            `${userFromBackend.first_name} ${userFromBackend.last_name}`,
          );
          setUserEmail(userFromBackend.email || '');
          setUserCpf(userFromBackend.document || '');
          setStreetAddress(userFromBackend.street_address || '');
          setAddressNumber(userFromBackend.address_number || '');
          setAddressComplement(userFromBackend.address_complement || '');
          setCity(userFromBackend.city || '');
          setStateId(userFromBackend.state_id);
          setZipCode(userFromBackend.zip_code || '');
        }
      })
      .catch((err) => {
        window.Rollbar.info('Failed to fetch user data on PreBooking', {
          error: err,
        });
        navigate('/your-dates');
      });
  };

  const fetchOrderData = () => {
    API.Order.find(orderId)
      .then((response) => {
        const { data: body } = response;

        if (body.data !== null) {
          const currentOrder = body.data;
          setOrder(currentOrder);

          // if order does not have a user id, set it
          if (!currentOrder.user_id) {
            API.Order.update(currentOrder.id, {
              status: 'pre_booking',
              user_id: currentUser.id,
            });
          }
        }
      })
      .catch((err) => {
        window.Rollbar.info('Failed to fetch order data on PreBooking', {
          error: err,
        });
        navigate('/your-dates');
      });
  };

  if (!user || !order) {
    return <Loading />;
  }

  const validForm = () => {
    const isValidUserName = userName.trim().length > 0;
    const isValidUserEmail = userEmail.trim().length > 0;
    const isValidUserCpf = userCpf.trim().length > 0 && validateCPF(userCpf);
    const isValidSignificantOtherName = significantOtherName.trim().length > 0;
    const isValidSignificantOtherCpf =
      significantOtherCpf.trim().length === 0
        ? true
        : validateCPF(significantOtherCpf);
    const isValidStreetAddress = streetAddress.trim().length > 0;
    const isValidAddressNumber = addressNumber.trim().length > 0;
    const isValidCity = city.trim().length > 0;
    const isValidStateId = parseInt(stateId, 10) > 0;
    const isValidZipCode = zipCode.trim().length > 0;

    setValidUserName(isValidUserName);
    setValidUserEmail(isValidUserEmail);
    setValidUserCpf(isValidUserCpf);
    setValidSignificantOtherName(isValidSignificantOtherName);
    setValidSignificantOtherCpf(isValidSignificantOtherCpf);
    setValidStreetAddress(isValidStreetAddress);
    setValidAddressNumber(isValidAddressNumber);
    setValidCity(isValidCity);
    setValidStateId(isValidStateId);
    setValidZipCode(isValidZipCode);

    return (
      isValidUserName &&
      isValidUserEmail &&
      isValidUserCpf &&
      isValidSignificantOtherName &&
      isValidSignificantOtherCpf &&
      isValidStreetAddress &&
      isValidAddressNumber &&
      isValidCity &&
      isValidStateId &&
      isValidZipCode
    );
  };

  const onClickHandler = () => {
    if (validForm()) {
      const data = {
        status: 'placed',
        user_name: userName,
        user_email: userEmail,
        user_cpf: userCpf,
        significant_other_name: significantOtherName,
        significant_other_email: significantOtherEmail,
        significant_other_cpf: significantOtherCpf,
        street_address: streetAddress,
        address_number: addressNumber,
        address_complement: addressComplement,
        city,
        state_id: stateId,
        zip_code: zipCode,
      };

      API.Order.update(order.id, data)
        .then(() => {
          MySwal.fire({
            title: <strong>Pré-agendamento confirmado</strong>,
            html: (
              <div>
                <p>Você ainda não fará o pagamento.</p>
                <p style={{ textAlign: 'justify', marginBottom: '0.75rem' }}>
                  Nós vamos encontrar o melhor dia para a sua experiência
                  baseado nas suas preferências.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  Em breve você receberá um e-mail para confirmar a data e
                  realizar o pagamento.
                </p>
              </div>
            ),
            icon: 'success',
          }).then(() => {
            navigate('/your-dates');
          });
        })
        .catch(() => {
          MySwal.fire({
            title: <strong>Oops</strong>,
            html: <i>Alguma coisa deu errada, tente novamente mais tarde :(</i>,
            icon: 'error',
          });
        });
    } else {
      MySwal.fire({
        title: <strong>Alto lá</strong>,
        html: (
          <i>
            Preencha os campos em vermelho para continuar. Verifique também se
            os CPFs estão corretos.
          </i>
        ),
        icon: 'error',
      });
    }
  };

  const { billing } = order;

  return (
    <Container className="main-content-container px-4 w-100 mx-auto">
      <Row className="page-header py-4">
        <PageTitle
          title="Confirmação de pré-agendamento"
          subtitle="Agora é só deixar com a gente"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>

      <Row>
        <Col lg="12" sm="12" className="mb-4">
          <Card
            small
            className="card-post card-post--aside card-post--1 flex-flow-column h-100"
          >
            <CardHeader className="border-bottom">
              <h5 className="m-0">Resumo</h5>
            </CardHeader>

            <div className="inherit column-mobile">
              <div
                className="card-post__image"
                style={{ backgroundImage: `url('${showImage(order)}')` }}
              ></div>
              <CardBody>
                <div className="card-text-2">
                  <h5 className="card-title" data-testid="date-title">
                    {order.date.title}
                  </h5>
                  <p className="card-text d-inline-block mb-3">
                    {order.date.description}
                    <br className="mb-2" />
                    <strong>Opção escolhida:</strong> {order.option.title}
                    <br className="mb-2" />
                    <strong>Valor: </strong>
                    {billing.formatted.price_with_discount}
                  </p>
                </div>
                <div className="card-details">
                  <span className="text-muted">
                    <strong>Pré-agendado em</strong>{' '}
                    {formatDate(order.inserted_at)}
                  </span>

                  {order.status === 'completed' && (
                    <>
                      <br />
                      <span className="text-muted">
                        <strong>Pago em</strong> {formatDate(order.paid_at)}
                      </span>
                    </>
                  )}
                </div>
              </CardBody>
            </div>
          </Card>
        </Col>
        <Col lg="12" sm="12" className="mb-4">
          <Card>
            <CardHeader className="border-bottom">
              <h5 className="m-0">Sua reserva</h5>
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="12" md="4" className="mb-3">
                  <h5 className="card-title">Data e hora</h5>
                  <p className="card-text d-inline-block mb-3">
                    <strong>A partir de </strong>
                    {formatDate(order.start_date)}
                    <br className="mb-2" />
                    <strong>Entre </strong>
                    {order.from_time} e {order.to_time}
                    <br className="mb-2" />
                    <strong>No(s) dia(s) </strong>
                    {API.Order.weekDaysString(order)}
                  </p>
                </Col>
                <Col sm="12" md="4" className="mb-3">
                  <h5 className="card-title">Detalhes escolhidos:</h5>
                  <div className="card-text d-inline-block mb-3">
                    <ul>
                      <li className="mb-1">{order.option.title}</li>
                      {order.comment && (
                        <li className="mb-1">{order.comment}</li>
                      )}
                    </ul>
                  </div>
                </Col>
                <Col sm="12" md="4" className="mb-3">
                  <h5 className="card-title">Comentário:</h5>
                  <p className="card-text d-inline-block mb-3">
                    {order.comment}
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg="12" sm="12" className="mb-4">
          <Card>
            <CardHeader className="border-bottom">
              <h5 className="m-0">Instruções</h5>
            </CardHeader>
            <CardBody>
              <Col sm="12" className="mb-3">
                <Row>
                  <Col>
                    <h5 className="card-title">
                      Aguardar a confirmação do agendamento
                    </h5>
                    <p className="card-text d-inline-block mb-1">
                      <strong>
                        Após a sua confirmação abaixo, faremos a sua pré-reserva
                        dentro dos limites do seu pré-agendamento. Com isso,
                        enviaremos um e-mail com a data e horário oficiais para
                        a sua reserva, que você poderá aceitar ou pedir uma
                        revisão. Caso aceite, o pagamento para o seu date estará
                        liberado.{' '}
                      </strong>
                    </p>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <h5 className="card-title">Fazer o pagamento</h5>
                    <p className="card-text d-inline-block mb-1">
                      <strong>
                        Ao aceitar a data e horário propostos, a página de
                        pagamento será liberada. Assim que o pagamento for
                        confirmado, sua reserva estará completa e só faltará
                        mais um passo: aproveitar!
                      </strong>
                    </p>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <h5 className="card-title">Aproveite</h5>
                    <p className="card-text d-inline-block mb-3">
                      <strong>
                        Com o pagamento confirmado, você reberá também os
                        detalhes da localização da sua experiência e só restará
                        aparecer na data e horário combinados e aproveitar muito
                        essa experiência única com quem você gosta!{' '}
                      </strong>
                    </p>
                  </Col>
                </Row>
              </Col>
            </CardBody>
          </Card>
        </Col>
        <Col lg="12" sm="12" className="mb-4">
          <UserData
            userName={userName}
            setUserName={setUserName}
            userEmail={userEmail}
            setUserEmail={setUserEmail}
            userCpf={userCpf}
            setUserCpf={setUserCpf}
            significantOtherName={significantOtherName}
            setSignificantOtherName={setSignificantOtherName}
            significantOtherEmail={significantOtherEmail}
            setSignificantOtherEmail={setSignificantOtherEmail}
            significantOtherCpf={significantOtherCpf}
            setSignificantOtherCpf={setSignificantOtherCpf}
            validUserName={validUserName}
            validUserEmail={validUserEmail}
            validUserCpf={validUserCpf}
            setValidUserCpf={setValidUserCpf}
            validSignificantOtherName={validSignificantOtherName}
            validSignificantOtherCpf={validSignificantOtherCpf}
            setValidSignificantOtherCpf={setValidSignificantOtherCpf}
            validStreetAddress={validStreetAddress}
            validAddressNumber={validAddressNumber}
            validCity={validCity}
            validStateId={validStateId}
            validZipCode={validZipCode}
            streetAddress={streetAddress}
            setStreetAddress={setStreetAddress}
            addressNumber={addressNumber}
            addressComplement={addressComplement}
            setAddressNumber={setAddressNumber}
            setAddressComplement={setAddressComplement}
            city={city}
            setCity={setCity}
            stateId={stateId}
            setStateId={setStateId}
            zipCode={zipCode}
            setZipCode={setZipCode}
          />
        </Col>
        <Row className="mx-auto mt-3 mb-5">
          <Button
            pill
            outline
            theme="danger"
            size="lg"
            className="m-2"
            onClick={() => {
              window.location.href = buildUrl(
                `${Config.storeUrl}/date/agende/${order.date.slug}/${order.option.label}`,
              );
            }}
          >
            Cancelar
          </Button>

          <Button
            pill
            size="lg"
            className="m-2"
            onClick={onClickHandler}
            data-testid="prebooking-confirmation-button"
          >
            Confirmar pré-agendamento
          </Button>
        </Row>
      </Row>
    </Container>
  );
};

export default PreBooking;
