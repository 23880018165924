/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Badge, Button, Card, CardBody, Col, Row, Tooltip } from 'shards-react';
import { useNavigate } from 'react-router-dom';

import { BADGE_INFO } from 'lib/badge-info';
import { formatDate } from 'lib/date-utils';
import { showDateImage as showImage } from 'lib/date-image';

const DateListItem = ({ order }) => {
  const navigate = useNavigate();

  const [openTooltip, setOpenTooltip] = useState(false);

  const toggle = () => {
    setOpenTooltip((o) => !o);
  };

  const badge = (options) => {
    let klass = 'item-badge';
    let id = `statusBadge_${order.id}`;
    let href = null;

    if (options.mobile) {
      klass = `${klass}-mobile`;
      id = `mobile_${id}`;
    }

    if (order.status === 'waiting_for_payment') {
      href = `/your-reservation?ref=${order.id}`;
      klass = `${klass} pointer`;
    }

    const currentBadge = () => (
      <Badge
        pill
        id={id}
        className={`${klass} ${BADGE_INFO[order.status].klass}`}
        style={
          order.status === 'ready'
            ? { animation: 'green-pulse 2s infinite' }
            : {}
        }
      >
        {BADGE_INFO[order.status].text}
      </Badge>
    );

    if (href) {
      // eslint-disable-next-line react/jsx-no-target-blank
      return (
        <a target="_blank" href={href}>
          {currentBadge()}
        </a>
      );
    }

    return currentBadge();
  };

  const { billing } = order;

  let cardClass = '';

  if (!order.number && order.status === 'pre_booking') {
    cardClass = 'date-card-disabled-background';
  } else if (order.status === 'canceled') {
    cardClass = 'date-card-canceled-background';
  }

  const continueOrder = () => {
    const preBookingUrl = `/pre-booking?ref=${order.id}`;
    navigate(preBookingUrl);
  };

  return (
    <Card
      small
      className="card-post card-post--aside card-post--1 flex-flow-column"
    >
      <div className={`inherit column-mobile border-bottom ${cardClass}`}>
        <div
          className="pointer card-post__image card-img-left-or-top"
          style={{ backgroundImage: `url('${showImage(order.date)}')` }}
          onClick={() => {
            if (order.number) {
              navigate(`/your-dates/${order.number}`);
            } else {
              continueOrder();
            }
          }}
        >
          {badge({ mobile: true })}
        </div>
        <CardBody className="date-list-item-info d-flex">
          <div className="card-text-2">
            <h5 className="card-title mb-1">
              {order.number && (
                <a
                  className="text-fiord-blue"
                  href={`/your-dates/${order.number}`}
                >
                  {order.date.title}
                </a>
              )}

              {!order.number && (
                <span className="pointer" onClick={continueOrder}>
                  {order.date.title}
                </span>
              )}
            </h5>
            {order.number && <p>Pedido {order.number}</p>}
            <p className="card-text d-inline-block mb-3">
              <strong>Opção escolhida:</strong> {order.option.title}
              <br />
              {order.scheduled_for && (
                <>
                  <strong>Agendado para:</strong>{' '}
                  {formatDate(order.scheduled_for)}
                  <br />
                </>
              )}
              <strong>Preço: </strong>
              {billing.formatted.price_with_discount} <br />
              {billing.balance_due > 0 && (
                <>
                  <strong>Valor a pagar: </strong>
                  {billing.formatted.balance_due} <br />
                </>
              )}
              {billing.balance_due < 0 && (
                <>
                  <strong>Valor a receber: </strong>
                  {billing.formatted.balance_due} <br />
                </>
              )}
            </p>
          </div>
          <div className="date-list-item-details">
            <div>
              <span className="text-muted">
                <strong>Criado em</strong> {formatDate(order.inserted_at)}
              </span>

              {order.status === 'waiting_for_payment' && order.expires_at && (
                <>
                  <br />
                  <span className="text-muted">
                    <strong>Expira em</strong> {formatDate(order.expires_at)}
                  </span>
                </>
              )}
            </div>
            {badge({ mobile: false })}
            <Tooltip
              open={openTooltip}
              target={`#statusBadge_${order.id}`}
              toggle={toggle}
            >
              {BADGE_INFO[order.status].tooltip}
            </Tooltip>
          </div>
        </CardBody>
        <Col className="card-side-buttons-container">
          {order.status === 'waiting_for_payment' && (
            <Button
              outline
              size="sm"
              theme="warning"
              className="mb-2 mr-1 display-flex"
              onClick={() => {
                const yourReservationUrl = `/your-reservation?ref=${order.id}`;
                navigate(yourReservationUrl);
              }}
              data-testid="date-pay-button"
            >
              <span className="material-icons">attach_money</span>
              <p>Pagar</p>
            </Button>
          )}

          {order.status !== 'pre_booking' && (
            <Button
              outline
              size="sm"
              theme="secondary"
              className="mb-2 mr-1 display-flex"
              onClick={() => {
                navigate(`/your-dates/${order.number}`);
              }}
              data-testid="date-details-button"
            >
              <span className="material-icons">subject</span>
              <p>Ver detalhes</p>
            </Button>
          )}

          {!order.number && order.status === 'pre_booking' && (
            <Button
              outline
              size="sm"
              theme="primary"
              className="mb-2 mr-1 display-flex"
              onClick={continueOrder}
              data-testid="date-continue-button"
            >
              <span className="material-icons">arrow_forward</span>
              <p>Continuar</p>
            </Button>
          )}
        </Col>
      </div>

      <CardBody
        small="true"
        className="d-lg-none card-bottom-buttons-container"
      >
        <Row className="justify-content-around">
          {order.status === 'waiting_for_payment' && (
            <Button
              outline
              size="sm"
              theme="warning"
              className="m-2 display-flex"
              onClick={() => {
                const yourReservationUrl = `/your-reservation?ref=${order.id}`;
                navigate(yourReservationUrl);
              }}
              data-testid="date-pay-button"
            >
              <span className="material-icons">attach_money</span>
              <p>Pagar</p>
            </Button>
          )}
          {order.status !== 'pre_booking' && (
            <Button
              outline
              size="sm"
              theme="secondary"
              className="m-2 display-flex"
              onClick={() => {
                navigate(`/your-dates/${order.number}`);
              }}
              data-testid="date-details-button"
            >
              <span className="material-icons">subject</span>
              <p>Ver detalhes</p>
            </Button>
          )}
          {!order.number && order.status === 'pre_booking' && (
            <Button
              outline
              size="sm"
              theme="primary"
              className="m-2 display-flex"
              onClick={continueOrder}
              data-testid="date-continue-button"
            >
              <span className="material-icons">arrow_forward</span>
              <p>Continuar</p>
            </Button>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default DateListItem;
