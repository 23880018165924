export const BADGE_INFO = {
  pre_booking: {
    text: 'Pedido iniciado',
    tooltip: 'Você precisa nos passar alguns dados.',
    klass: '',
  },
  placed: {
    text: 'Pré-agendado',
    tooltip: 'Em breve você receberá um e-mail com o agendamento.',
    klass: 'bg-info',
  },
  waiting_for_payment: {
    text: 'Pendente',
    tooltip: 'Pagamento pendente.',
    klass: 'bg-warning',
  },
  waiting_for_changes: {
    text: 'Em edição',
    tooltip:
      'Você pediu alguma edição no seu pedido e estamos trabalhando nisso!',
    klass: 'bg-primary',
  },
  // This status no longer exists, but need to be here for backward compatibility
  paid: {
    text: 'Em breve',
    tooltip: 'Está tudo certo com o seu date, agora é só esperar!',
    klass: '',
  },
  ready: {
    text: 'Em breve',
    tooltip: 'Está tudo certo com o seu date, agora é só esperar!',
    klass: '',
  },
  completed: {
    text: 'Concluído',
    tooltip: 'Esperamos que tenham se divertido!',
    klass: 'bg-dark',
  },
  canceled: {
    text: 'Cancelado',
    tooltip: 'Infelizmente esse date foi cancelado.',
    klass: 'bg-danger',
  },
  didnt_happen: {
    text: 'Não aconteceu',
    tooltip: 'Esse date não aconteceu.',
    klass: 'bg-secondary',
  },
};
