import React from 'react';

import 'views/Reservation/styles.scss';

const PaymentSummary = ({ order, removeStoreCredits }) => {
  const { billing } = order;
  const { formatted } = billing;

  return (
    <section className="payment-summary">
      <table className="w-100">
        <tbody>
          {billing.has_discount && (
            <tr>
              <td className="label">Preço original:</td>
              <td className="monetary-value strikethrough">
                {billing.formatted.price}
              </td>
            </tr>
          )}

          {billing.discount > 0 && (
            <tr>
              <td className="label">Desconto:</td>
              <td className="monetary-value">{billing.discount_percentage}</td>
            </tr>
          )}
          <tr>
            <td className="label">
              {billing.discount > 0 && 'Preço atual:'}
              {billing.discount <= 0 && 'Preço:'}
            </td>

            <td className="monetary-value">{formatted.price_with_discount}</td>
          </tr>

          <tr>
            <td className="label">Taxas:</td>
            <td className="monetary-value">R$ 0,00</td>
          </tr>

          {billing.value_paid_with_money !== 0 && (
            <tr>
              <td className="label">Valores pagos:</td>
              <td
                className={`monetary-value ${
                  billing.value_paid_with_money < 0
                    ? 'to-deduct-from-total'
                    : ''
                }`}
              >
                {formatted.value_paid_with_money}
              </td>
            </tr>
          )}

          {billing.value_paid_with_store_credits !== 0 && (
            <tr>
              <td className="label flex-column">
                Créditos aplicados:
                <span
                  className="remove-store-credits-button"
                  onClick={removeStoreCredits}
                >
                  Remover
                </span>
              </td>
              <td
                className={`monetary-value ${
                  billing.value_paid_with_store_credits < 0
                    ? 'to-deduct-from-total'
                    : ''
                }`}
              >
                {formatted.value_paid_with_store_credits}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <hr />

      <table className="w-100">
        <tr>
          <td className="label">Total:</td>
          <td className="monetary-value">{formatted.balance_due}</td>
        </tr>
      </table>
    </section>
  );
};

export default PaymentSummary;
